import config from '../config';
import Request from '../helpers/request';
import { StatusDto } from '../typings';
import { DepositChannelDto } from '../typings/accounts';
import { ExchangeRate } from '../typings/rates';
import { LoginSource, ReferralData, TokenDto, UserDto } from '../typings/user';
import { getQueryParams } from '../utils';
import { CardDeclinePlatforms, CardDeclineStat, CardTransactionDto, CardTransactionListDto, CardTransactionType } from '../typings/cards';
import { CardsListDto } from '../typings/cards';
import { CardSupportedCurrencyListDto } from '../typings/cards';
import {
  BankList,
  WithdrawalAccountDto,
  WithdrawalAccountListDto,
  WithdrawalAccountLookupResponse,
  WithdrawalAccountType,
  WithdrawalRequestDto,
} from '../typings/withdrawal';

export const getVersion = async (): Promise<{ version: string }> => {
  return await Request.get<{ version: string }>(`${config.api.swfc}/version`);
};

export const authExternal = async (token: string, source: LoginSource, referrerCode?: string): Promise<TokenDto> => {
  return await Request.post<TokenDto>(`${config.api.swfc}/auth/external`, {
    data: {
      token,
      source,
      referrerCode,
    },
  });
};

export const getActiveUser = async (): Promise<UserDto> => {
  return await Request.getWithUserToken<UserDto>(`${config.api.swfc}/me`);
};

export const updateUser = async (data: Partial<UserDto>) => {
  return await Request.patchWithUserToken<UserDto>(`${config.api.swfc}/me`, {
    data,
  });
};

export const verifyKyc = async (data: { [x: string]: any }): Promise<StatusDto> => {
  const { idType, idNumber, country, attempts, methods } = data;

  return await Request.postWithUserToken<StatusDto>(`${config.api.swfc}/auth/kyc/verify`, {
    data: {
      idType,
      idNumber,
      country,
      attempts,
      methods,
    },
  });
};

export const confirmKyc = async (pin: string): Promise<TokenDto> => {
  return await Request.postWithUserToken<TokenDto>(`${config.api.swfc}/auth/kyc/confirm`, {
    data: {
      pin,
    },
  });
};

export const getExchangeRate = async (fromCurrency: string, toCurrency: string) => {
  return await Request.get<ExchangeRate>(`${config.api.swfc}/rates${getQueryParams({ fromCurrency, toCurrency })}`);
};

export const getDepositChannel = async (): Promise<DepositChannelDto> => {
  return await Request.getWithUserToken<DepositChannelDto>(`${config.api.swfc}/me/deposit/channel`);
};

export const getCards = async (page = 1, size = 20, currency?: string): Promise<CardsListDto> => {
  return await Request.getWithUserToken<CardsListDto>(
    `${config.api.swfc}/cards${getQueryParams({
      currency,
      page,
      size,
    })}`,
  );
};

export const freezeCard = async (id: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${config.api.swfc}/cards/${id}/freeze`);
};

export const unfreezeCard = async (id: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${config.api.swfc}/cards/${id}/unfreeze`);
};

export const getCardTransactions = async (data: {
  page?: number;
  size?: number;
  cardId?: string;
  currency?: string;
  type?: CardTransactionType;
  status?: CardTransactionType;
}): Promise<CardTransactionListDto> => {
  const { page = 1, size = 30, cardId, currency, type, status } = data;
  return await Request.getWithUserToken<CardTransactionListDto>(
    `${config.api.swfc}/transactions${getQueryParams({
      page,
      size,
      cardId,
      currency,
      type,
      status,
    })}`,
  );
};

export const getSupportedCurrencies = async (): Promise<CardSupportedCurrencyListDto> => {
  return await Request.get<CardSupportedCurrencyListDto>(`${config.api.swfc}/supported/currencies`);
};

export const getBanksList = async (countryCode = 'NG'): Promise<BankList> => {
  return await Request.getWithUserToken<BankList>(`${config.api.swfc}/banks/${countryCode}/list`);
};

export const withdrawFunds = async (data: WithdrawalRequestDto): Promise<CardTransactionDto> => {
  return await Request.postWithUserToken<CardTransactionDto>(`${config.api.swfc}/withdraw`, { data });
};

export const getReferralData = async (): Promise<ReferralData> => {
  return await Request.getWithUserToken<ReferralData>(`${config.api.swfc}/referral`);
};

export const lookupAccount = async (bankId: string, accountNumber: string): Promise<WithdrawalAccountLookupResponse> => {
  return await Request.postWithUserToken<WithdrawalAccountLookupResponse>(`${config.api.withdrawal}/me/accounts/lookup`, {
    data: {
      bankId,
      accountNumber,
    },
  });
};

export const getAccounts = async (type?: WithdrawalAccountType): Promise<WithdrawalAccountListDto> => {
  return await Request.getWithUserToken<WithdrawalAccountListDto>(`${config.api.withdrawal}/me/accounts${getQueryParams({ type })}`);
};

export const addAccount = async (data: WithdrawalAccountDto): Promise<WithdrawalAccountDto> => {
  return await Request.putWithUserToken<WithdrawalAccountDto>(`${config.api.withdrawal}/me/accounts`, {
    data,
  });
};

export const removeAccount = async (id: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${config.api.withdrawal}/me/accounts/${id}`);
};

export const getDeclineStats = async (cardId: string): Promise<CardDeclineStat> => {
  return await Request.getWithUserToken<CardDeclineStat>(`${config.api.swfc}/stats/decline/data?cardId=${cardId}`);
};

export const getDeclinePlatforms = async (cardId: string): Promise<CardDeclinePlatforms> => {
  return await Request.getWithUserToken<CardDeclinePlatforms>(`${config.api.swfc}/stats/decline/platforms?cardId=${cardId}`);
};
