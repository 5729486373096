import { combineReducers } from 'redux';
import { removeState } from '../localstorage';
import { LOCAL_APP_TOKEN } from '../constants';
import user from './user';
import misc from './misc';
import lists from './lists';
import kyc from './kyc';
import rates from './rates';
import cards from './cards';
import accounts from './accounts';
import mixpanel from '../mixpanel';

export const appReducer = combineReducers({
  user,
  cards,
  misc,
  lists,
  kyc,
  rates,
  accounts,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STATES') {
    mixpanel.reset();
    removeState(LOCAL_APP_TOKEN);
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
