import React from 'react';
import one from '../../assets/hs-steps-android/M1.svg';
import two from '../../assets/hs-steps-android/M2.svg';
import three from '../../assets/hs-steps-android/M3.svg';
import './styles.scss';
import Gap from '../Gap';

const Andriod = () => {
  return (
    <>
      <Gap v={2} />

      <ul className="hs-list-steps">
        <li data-n="01">
          <p>
            Open LaserCards on any browser, and locate the <span className="text-pink">Share</span> Icon
          </p>

          <div className="img-holder">
            <img src={one} alt="step1" />
          </div>
        </li>

        <li data-n="02">
          <p>
            Find the <span className="text-pink">"Add Page To"</span>option then the <span className="text-pink">"Home Screen"</span> option
          </p>

          <div className="img-holder">
            <img src={two} alt="step2" />
          </div>
        </li>

        <li data-n="03">
          <p>
            Confirm this action by tapping on the <span className="text-pink"> “Add”</span> button, and you’re done
          </p>

          <div className="img-holder">
            <img src={three} alt="step3" />
          </div>
        </li>
      </ul>
    </>
  );
};

export default Andriod;
