const apiUrl = process.env.REACT_APP_API_URL;

export default {
  variant: process.env.REACT_APP_VARIANT || 'STAGING',
  api: {
    kyc: `${apiUrl}/kyc`,
    swfc: `${apiUrl}/swiftcards`,
    cards: `${apiUrl}/cards`,
    lists: `${apiUrl}/lists`,
    withdrawal: `${apiUrl}/withdrawal`,
  },
};
