import { AccountsAction, AccountsState } from '../typings/accounts';

const initialState: AccountsState = {};

export default (state = initialState, action: AccountsAction): Partial<AccountsState> => {
  switch (action.type) {
    case 'SET_DEPOSIT_CHANNEL':
      return {
        ...state,
        depositChannel: action.depositChannel,
      };
    default:
      return state;
  }
};
