import { CardDto, CardSupportedCurrencyDto, CardsAction } from '../typings/cards';

export const setCards = (cards: CardDto[]): CardsAction => ({
  type: 'SET_CARDS',
  cards,
});

export const updateCard = (cardId: string, card: Partial<CardDto>): CardsAction => ({
  type: 'UPDATE_CARD',
  cardId,
  card,
});

export const setCurrencies = (currencies: CardSupportedCurrencyDto[]): CardsAction => ({
  type: 'SET_CURRENCIES',
  currencies,
});
