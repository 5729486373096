import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { route } from '../../routes';
import { RatesInfo } from '../RatesInfo';
import * as swfcApi from '../../api/swiftcards';
import * as miscActions from '../../actions/misc';
import * as cardActions from '../../actions/cards';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../typings/reducer';
import { CardDto } from '../../typings/cards';
import Gap from '../Gap';
import mixpanel from '../../mixpanel';

type HeaderProps = {
  showOptions?: boolean;
  authenticated?: boolean;
  backButton?: boolean;
  backButtonClick?: () => void;
  cardId?: string;
  text?: string;
  subText?: string;
};

export const Header = (props: HeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticated = true, showOptions = true, cardId, text, subText, backButton, backButtonClick } = props;
  const [card, setCard] = useState<CardDto | undefined>();
  const { cards } = useSelector((state: ReducerStates) => state);

  useEffect(() => {
    setCard(cards.cards?.find((card) => card.id === props.cardId));
  }, [cards.cards]);

  return (
    <>
      <div className="app-head app-head-default">
        {backButton ? (
          <a className="cursor-pointer" onClick={() => (backButtonClick ? backButtonClick() : navigate(route.home, { replace: true }))}>
            <span className="icon-back-button lg"></span>
          </a>
        ) : (
          <img src={require('../../assets/logo.png')} alt="" height={30} />
        )}

        <RatesInfo />

        {authenticated && showOptions ? (
          <Menu triggerSubMenuAction="click">
            <SubMenu
              title={
                <a className="options">
                  <BsThreeDotsVertical />
                </a>
              }
              key="1"
            >
              {cardId && card ? (
                <>
                  <MenuItem key={`card-${cardId}-withdraw`} onClick={() => navigate(route.withdraw.replace(':cardId', cardId))}>
                    Withdraw
                  </MenuItem>

                  <MenuItem key={`account-${cardId}-referral`} onClick={() => navigate(route.referral)}>
                    Earn USD
                  </MenuItem>

                  <MenuItem
                    key={`card-${cardId}-freeze_unfreeze`}
                    onClick={async () => {
                      if (!['ACTIVE', 'FROZEN'].includes(card?.status!)) {
                        return;
                      }

                      dispatch(miscActions.updateMiscSettings({ cardAction: true }));

                      try {
                        if (card?.status === 'ACTIVE') {
                          await swfcApi.freezeCard(card?.id!);
                          mixpanel.track('Click-Button-FreezeCard');
                          dispatch(cardActions.updateCard(cardId, { status: 'FROZEN' }));
                        } else {
                          await swfcApi.unfreezeCard(card?.id!);
                          mixpanel.track('Click-Button-UnfreezeCard');
                          dispatch(cardActions.updateCard(cardId, { status: 'ACTIVE' }));
                        }
                      } finally {
                        dispatch(miscActions.updateMiscSettings({ cardAction: false }));
                      }
                    }}
                  >
                    {card.status === 'FROZEN' ? 'Unfreeze' : 'Freeze'}
                  </MenuItem>
                </>
              ) : null}

              <MenuItem
                key="logout"
                className="color-e5383b"
                onClick={() => {
                  mixpanel.reset();
                  dispatch({ type: 'RESET_STATES' });
                  navigate(route.auth, { replace: true });
                }}
              >
                Logout
              </MenuItem>
            </SubMenu>
          </Menu>
        ) : (
          <Gap h={2} />
        )}
      </div>

      {text ? <p className="text-center mt-4 font-weight-600 pb-0 mb-0">{text}</p> : null}
      {subText ? <p className="text-center pt-2 text-muted max-w-350x margin-auto_center">{subText}</p> : null}
    </>
  );
};
