import React from 'react';

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string | JSX.Element;
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { text, loading, disabled, className, ...restProps } = props;

  return (
    <button className={`btn ${disabled ? 'btn-secondary' : 'btn-primary'} ${className}`} disabled={loading || disabled} {...restProps}>
      {loading ? 'Please wait...' : text}
    </button>
  );
};
