import { KycAction, KycState, ProfileKycDetailsDto } from '../typings/kyc';

const initialState: KycState = {};

export default (state = initialState, action: KycAction): Partial<KycState> => {
  switch (action.type) {
    case 'SET_INDIVIDUAL_KYC':
      return {
        ...state,
        individual: action.details as ProfileKycDetailsDto,
      };
    case 'UPDATE_KYC_INFO':
      return {
        ...state,
        individual: {
          ...state.individual!,
          ...action.details,
        },
      };
    default:
      return state;
  }
};
