import { UserAction, UserDto } from '../typings/user';

const initialState: Partial<UserDto> = {};

export default (state = initialState, action: UserAction): Partial<UserDto> => {
  switch (action.type) {
    case 'SET_USER':
      return action.user;
    case 'UPDATE_USER':
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
};
