/**
 * Loads an item from localstorage
 * @param {string} state Item name
 * @return {any}
 */
export const loadState = (state: string): any => {
  try {
    const serializedState = localStorage.getItem(state);

    if (serializedState === null) return undefined;

    return JSON.parse(serializedState);
  } catch (err) {
    console.log('Unable to get local state.', err.message, err.stack);
  }
};

export const removeState = (key: string): void => {
  localStorage.removeItem(key);
};

export const loadStateWithExpiry = (key: string): any => {
  const data = loadState(key);

  if (!data) {
    return null;
  }

  if (data.expiresIn) {
    const secondsLeft = (data.expiresIn - new Date().getTime()) / 1000;

    if (secondsLeft <= 0) {
      removeState(key);
      return null;
    }
  }

  return data;
};

/**
 * Saves a state to localstorage
 * @param {string} state Item name
 * @param {any} data Item save data
 */
export const saveState = (state: string, data: any): void => {
  try {
    const serializedState = JSON.stringify(data);
    localStorage.setItem(state, serializedState);
  } catch (err) {
    // Ignore write errors for now
    console.log('Unable to save local state.', err.message, err.stack);
  }
};
