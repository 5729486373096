import React from 'react';
import Gap from '../Gap';
import mixpanel from '../../mixpanel';

export const Footer = () => (
  <div className="footer">
    <ul className="links">
      <li>
        <a href="https://lasercards.super.site/terms" onClick={() => mixpanel.track('Click-Link-Terms')}>
          Terms
        </a>
      </li>
      <li>
        <a href="https://lasercards.super.site/privacy" onClick={() => mixpanel.track('Click-Link-Privacy')}>
          Privacy
        </a>
      </li>
      <li>
        <a href="https://blog.lasercards.co" onClick={() => mixpanel.track('Click-Link-Blog')}>
          Blog
        </a>
      </li>
      <li>
        <a href="https://notion.so/8ca9fd4dd5e3491da03135760ac0551e" onClick={() => mixpanel.track('Click-Link-Help_FAQs')}>
          Help & FAQs
        </a>
      </li>
    </ul>

    <div className="text-center mt-4">
      <img src={require('../../assets/whitelogo.png')} alt="" height={30} />
    </div>

    <Gap v={1} />
    <div className="text-center">
      <div className="address mb-2">Suite 409, Nawa Complex, Jahi, F.C.T, Abuja</div>
      <a href="mailto:support@lasercards.co" onClick={() => mixpanel.track('Click-Link-Email_Support')}>
        support@lasercards.co
      </a>
    </div>

    <Gap v={2} />
  </div>
);
