import React, { useEffect, useState } from 'react';
import Cleave from 'cleave.js/react';
import { useParams, Link } from 'react-router-dom';
import { Header } from '../../components/header';
import Gap from '../../components/Gap';
import { Select2 } from '../../components/html/Select';
import { Button } from '../../components/html/Button';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../typings/reducer';
import { formatCurrency, handleRequestErrors } from '../../utils';
import { BankList, WithdrawalAccountDto, WithdrawalAccountListDto } from '../../typings/withdrawal';
import * as swfcApi from '../../api/swiftcards';
import mixpanel from '../../mixpanel';
import Slider from 'react-slick';
import { loadState, saveState } from '../../localstorage';
import { RadioButton } from '../../components/html/RadioButton';
import * as cardsActions from '../../actions/cards';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import { route } from '../../routes';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export const Withdraw = () => {
  const dispatch = useDispatch();
  const [enabled] = useState(true);
  const { cardId }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [stage, setStage] = useState<'DETAILS' | 'CONFIRMATION' | 'SUCCESS' | 'FAILURE'>('DETAILS');
  const [errorMessage] = useState<string | null>();
  const { user, kyc, cards, rates, misc } = useSelector((state: ReducerStates) => state);
  const [bankList, setBankList] = useState<BankList>({ banks: [] });
  const [privacy, setPrivacy] = useState(false);
  const [userAccounts, setUserAccounts] = useState<WithdrawalAccountDto[]>([]);
  const [amount, setAmount] = useState(0);
  const [feeAmount] = useState(100);
  const [bankId, setBankId] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [saveAccount, setSaveAccount] = useState(false);
  const [country, setCountryCode] = useState('');

  const localCurrency = user.currency || 'NGN';
  const card = cards?.cards?.find((card) => ['ACTIVE', 'FROZEN'].includes(card.status))!;
  const sellRate = Number(rates.find((rate) => rate.toCurrency === localCurrency && rate.fromCurrency === 'USD')?.rate || 0);

  useEffect(() => {
    const privacyState = loadState('privacy');
    setPrivacy(privacyState);
  }, []);

  useEffect(() => {
    if (!kyc.individual?.location.country) {
      return;
    }

    const [, , countryCode] = kyc.individual.location.country.split('::');

    setCountryCode(countryCode);
    mixpanel.track('Card-Withdrawal');
    swfcApi.getBanksList(countryCode).then((response) => setBankList(response));
  }, [kyc.individual?.location.country]);

  useEffect(() => {
    swfcApi
      .getAccounts()
      .then((result: WithdrawalAccountListDto) => setUserAccounts(result.accounts))
      .catch(handleRequestErrors)
      .finally(() => setLoadingAccounts(false));
  }, []);

  const balanceAndLimitsCheck = () => {
    let proceed = false;

    if (amount > card.balance) {
      toast.error('Insufficient funds on card');
    } else if (amount > 1000) {
      toast.error('Max withdrawal is $1,000 per transaction');
    } else {
      proceed = true;
    }

    return proceed;
  };

  const updatePrivacy = () => {
    setPrivacy(!privacy);
    saveState('privacy', !privacy);
  };

  const lookupBankAccount = (bankId: string, accountNumber: string) => {
    const account = userAccounts.find((account) => account.accountNumber === accountNumber);

    if (account) {
      setAccountName(account.accountName!);
      setBankName(account.bankName!);

      if (!balanceAndLimitsCheck()) {
        return;
      }

      setStage('CONFIRMATION');

      return;
    }

    if (!(bankId && accountNumber)) {
      return;
    }

    setLoading(true);

    swfcApi
      .lookupAccount(bankId, accountNumber)
      .then((result) => {
        setAccountName(result.accountName);
        setBankName(result.bankName);

        if (!balanceAndLimitsCheck()) {
          return;
        }

        setStage('CONFIRMATION');
      })
      .finally(() => setLoading(false));
  };

  const initiateWithdrawal = () => {
    setLoading(true);

    swfcApi
      .withdrawFunds({
        cardId,
        amount,
        currency: localCurrency,
        recipient: {
          type: 'BANK',
          bankId,
          accountNumber,
          remember: saveAccount,
        },
      })
      .then(async () => {
        const account = userAccounts.find((account) => account.accountNumber === accountNumber);

        if (saveAccount && !account) {
          await swfcApi
            .addAccount({
              type: 'BANK',
              accountNumber,
              accountName,
              bankId,
              country,
              currency: localCurrency,
            })
            .catch(handleRequestErrors);
        }

        setStage('SUCCESS');
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        swfcApi.getCards().then((cards) => dispatch(cardsActions.setCards(cards.records)));
      });
  };

  const removeAccount = (_accountNumber: string) => {
    const account = userAccounts.find((acct) => acct.accountNumber === _accountNumber);
    const response = window.confirm('Are you sure you want to remove this account?');

    if (response) {
      swfcApi
        .removeAccount(account?.id!)
        .then(() => {
          toast.success('Account was removed successfully');

          setUserAccounts(userAccounts.filter((acct) => acct.accountNumber !== _accountNumber));

          if (account?.accountNumber === accountNumber) {
            setBankId('');
            setBankName('');
            setAccountName('');
            setAccountNumber('');
          }
        })
        .catch(handleRequestErrors);
    }
  };

  return !misc.gottenCards ? (
    <div className="app-container">
      <div className="app-wrap">
        <div className="global-loading-container"></div>
      </div>
    </div>
  ) : (
    <>
      <div className="app-container">
        <div className="app-wrap">
          <div className="home-container">
            <div className="app-inner">
              {!enabled ? (
                <>
                  <Header cardId={cardId} text={enabled ? 'Withdraw Balance' : ''} backButton={true} />

                  <Gap v={1} />

                  <div className="text-center">
                    <Gap v={5} />

                    <div className="max-w-350x margin-auto_center">
                      <div className="icon-warning" style={{ width: 70, height: 70 }}></div>
                      <h3 className="mt-4">Withdraw Balance</h3>
                      <p className="color-r255-g255-b255-a50 mt-3">
                        Withdrawals are temporarily disabled at the moment, we'll let you know as soon as it's available.
                      </p>
                    </div>
                  </div>
                </>
              ) : stage === 'SUCCESS' ? (
                <>
                  <div className="status-wrapper">
                    <div className="inner">
                      <img src={require('../../assets/success.png')} alt="" width={100} height={100} />
                      <h5 className="font-weight-600 mt-3">Withdrawal Successful</h5>
                      <p className="text-muted max-w-350x margin-auto_center">We'll let you know as soon as your withdrawal is completed</p>

                      <Link to={route.home}>
                        <Button text="Continue" className="mt-5" />
                      </Link>
                    </div>
                  </div>
                </>
              ) : stage === 'FAILURE' ? (
                <>
                  <div className="status-wrapper">
                    <div className="inner">
                      <img src={require('../../assets/warning-2.png')} alt="" />
                      <h5 className="font-weight-600 mt-3">Withdrawal Failed</h5>
                      <p className="text-muted max-w-350x margin-auto_center">
                        Unfortunately, your withdrawal wasn't successful. Please try again later.
                      </p>

                      <Link to={route.home}>
                        <Button text="Continue" className="mt-5" />
                      </Link>
                    </div>
                  </div>
                </>
              ) : stage === 'CONFIRMATION' ? (
                <>
                  <Header
                    cardId={cardId}
                    text="Confirm Transaction"
                    subText="Please confirm the details of your transaction before you proceed."
                    backButton={true}
                    backButtonClick={() => setStage('DETAILS')}
                    showOptions={false}
                  />

                  <Gap v={2} />

                  <div className="confirm-wrapper">
                    <div className="confirm-item">
                      <div className="lhs">Transaction Type</div>
                      <div className="rhs">Card Withdrawal</div>
                    </div>

                    <div className="confirm-item">
                      <div className="lhs">Rate</div>
                      <div className="rhs">
                        {formatCurrency(sellRate?.toString().startsWith('0.0') ? Number(1 / sellRate) : sellRate, localCurrency)}
                      </div>
                    </div>

                    <div className="confirm-item">
                      <div className="lhs">You Pay</div>
                      <div className="rhs">{formatCurrency(amount, card.currency)}</div>
                    </div>

                    <div className="confirm-item">
                      <div className="lhs">You Receive</div>
                      <div className="rhs">{formatCurrency(amount * sellRate - feeAmount, localCurrency)}</div>
                    </div>

                    <div className="confirm-item">
                      <div className="lhs">Fee</div>
                      <div className="rhs">{formatCurrency(feeAmount, localCurrency)}</div>
                    </div>

                    <div className="confirm-item">
                      <div className="lhs">Bank Name</div>
                      <div className="rhs">{bankName}</div>
                    </div>

                    <div className="confirm-item">
                      <div className="lhs">Account Number</div>
                      <div className="rhs">{accountNumber}</div>
                    </div>

                    <div className="confirm-item">
                      <div className="lhs">Account Name</div>
                      <div className="rhs">{accountName}</div>
                    </div>

                    <div className="confirm-item-last">
                      <div className="lhs">Processing Time</div>
                      <div className="rhs">~ 10 minutes</div>
                    </div>
                  </div>

                  <Gap v={2} />

                  <Button loading={loading} text={loading ? 'Please wait...' : 'Continue'} onClick={initiateWithdrawal} />
                </>
              ) : (
                <>
                  <Header
                    cardId={cardId}
                    text="Make A Withdrawal"
                    subText="Withdraw your funds directly from your card to your bank account"
                    backButton={true}
                  />

                  <Gap v={2} />

                  <div className="balance-container">
                    <div className="text-center margin-auto_center max-w-320x cursor-pointer pt-3">
                      <a className="color-ffffff font-weight-600">
                        My Balance <span className="icon-eye" onClick={() => updatePrivacy()}></span>
                      </a>
                    </div>

                    <div className="balance-info text-center">
                      <h1 className="mt-1">{privacy ? '****' : formatCurrency(card.balance, card.currency)}</h1>

                      <p className="equiv">
                        {privacy ? null : (
                          <>
                            <span className="icon-equiv"></span> &nbsp;
                          </>
                        )}
                        {privacy ? '****' : formatCurrency(card.balance * sellRate, localCurrency)}
                      </p>
                    </div>
                  </div>

                  <Gap v={2} />

                  <div className="form-group amount-input-container">
                    <label htmlFor="input-amount" className="mb-2">
                      Amount
                    </label>

                    <div className="amount-field">
                      <Cleave
                        required
                        key="input-amount"
                        id="input-amount"
                        name="amount"
                        className="form-control-two"
                        placeholder={`0.00`}
                        autoComplete="off"
                        value={amount}
                        options={{
                          prefix: '$ ',
                          numeral: true,
                          numeralDecimalScale: 2,
                          stripLeadingZeroes: false,
                          numeralThousandsGroupStyle: 'thousand',
                        }}
                        onChange={(ev) => setAmount(Number(ev.target.value.replace(/[^\d.-]/g, '')))}
                      />
                      <span className="font-weight-500" onClick={() => setAmount(card.balance)}>
                        Max
                      </span>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div>
                        <small className="text-muted font-weight-500">Balance:</small>
                        <small className="ms-1">
                          <b>{formatCurrency(card.balance, card.currency)}</b> ~{' '}
                          <span className="text-muted">{formatCurrency(card.balance * sellRate, localCurrency)}</span>{' '}
                        </small>
                      </div>

                      <div>
                        <small className="text-muted font-weight-500">Fee:</small>
                        <small className="ms-1">
                          <b>{formatCurrency(100, localCurrency)}</b>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="tab-wrap">
                    <Slider
                      dots={true}
                      infinite={false}
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                      verticalSwiping={false}
                      arrows={false}
                      adaptiveHeight={true}
                      customPaging={(i) => {
                        const NavOptions: { [x: number]: string } = { 0: 'Saved Accounts', 1: 'New Account' };

                        return (
                          <div className="tab-nav font-weight-600" key={`${NavOptions[i]}-${i}`}>
                            <span>{NavOptions[i]}</span>
                          </div>
                        );
                      }}
                    >
                      <div className="tab-content tab-saved-accounts">
                        {loadingAccounts ? (
                          <div className="loading-area text-center my-3">
                            <Gap v={4} />

                            <span className="icon-loading"></span>
                          </div>
                        ) : userAccounts.length === 0 ? (
                          <div>
                            <Gap v={3} />

                            <div className="note note-style-2 warning">
                              <h6 className="font-weight-600">Bank Accounts</h6>
                              <p className="font-weight-500">
                                We recommend having at least one account.
                                <br />
                                Make a transaction to save your bank account.
                              </p>
                            </div>

                            <Gap v={2} />
                          </div>
                        ) : (
                          <>
                            <Gap v={3} />
                            <div style={{ textAlign: 'center' }} className="font-weight-600">
                              Select An Account
                            </div>
                            <Gap v={2} />

                            <ul className="account-details-container">
                              {userAccounts.map((acct) => (
                                <li key={acct.accountNumber} className={acct.accountNumber === accountNumber ? 'selected' : ''}>
                                  <div className="wrap">
                                    <RadioButton
                                      checked={acct.accountNumber === accountNumber}
                                      value={acct.accountNumber}
                                      onChange={(ev) => {
                                        const account = userAccounts.find((account) => account.accountNumber === ev.target.value);
                                        setBankId(account?.bankId!);
                                        setBankName(account?.bankName!);
                                        setAccountName(account?.accountName!);
                                        setAccountNumber(account?.accountNumber!);
                                      }}
                                    />

                                    <div className="inner">
                                      <span className="left">
                                        <img
                                          src={
                                            // prettier-ignore
                                            acct.accountNumber === accountNumber ? require('../../assets/bank.png') :require('../../assets/bank-two.png')
                                          }
                                          alt=""
                                          width={40}
                                          height={40}
                                        />
                                      </span>

                                      <div className="info">
                                        <div
                                          className={`acct-num font-weight-500 ${
                                            acct.accountNumber === accountNumber ? 'text-white' : 'text-gray'
                                          }`}
                                        >
                                          {acct.accountNumber}
                                        </div>

                                        <div className="account-details">
                                          <span className="font-small">{acct.bankName}</span>
                                          <span className="separator"></span>
                                          <span className="font-small">{acct.accountName}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <span className="right" onClick={() => removeAccount(acct.accountNumber)}>
                                    <img src={require('../../assets/trash.png')} alt="" width={25} height={25} />
                                  </span>
                                </li>
                              ))}
                            </ul>

                            <Gap v={4} />

                            <Button
                              loading={loading}
                              disabled={!amount || !(accountNumber.length >= 9)}
                              text={loading ? 'Please wait...' : 'Continue'}
                              onClick={() => {
                                if (!balanceAndLimitsCheck()) {
                                  return;
                                }

                                setStage('CONFIRMATION');
                              }}
                            />
                          </>
                        )}
                      </div>

                      <div className="tab-content tab-new-accounts">
                        <Gap v={3} />

                        <p style={{ textAlign: 'center' }} className="font-weight-600">
                          Your Account Details
                        </p>

                        <Select2
                          required
                          disabled={loading}
                          id="sel-bank"
                          name="bankId"
                          label="Bank Name"
                          options={bankList.banks.map(({ bankId, bankName }) => ({ label: bankName, value: bankId }))}
                          onChange={(ev) => {
                            setBankName('');
                            setBankId(ev.target.value);
                          }}
                          value={bankId}
                          gapbottom={2}
                        />

                        <div className="form-group">
                          <label htmlFor="input-account" className="mb-2">
                            Account Number
                          </label>

                          <Cleave
                            required
                            disabled={loading}
                            key="input-account"
                            id="input-account"
                            name="accountNumber"
                            className="form-control"
                            placeholder="1234567890"
                            autoComplete="off"
                            options={{
                              numeral: true,
                              numeralDecimalScale: 0,
                              numeralPositiveOnly: false,
                              numeralThousandsGroupStyle: 'none',
                              stripLeadingZeroes: false,
                            }}
                            value={accountNumber}
                            onChange={(ev) => {
                              const account = userAccounts.find((account) => account.accountNumber === ev.target.value);

                              if (account) {
                                setBankId(account?.bankId!);
                                setBankName(account?.bankName!);
                                setAccountName(account?.accountName!);
                                setAccountNumber(account?.accountNumber!);
                              } else {
                                setAccountName('');
                                setAccountNumber(ev.target.value);
                              }
                            }}
                          />
                          <p className="mt-2 font-weight-500">{accountName}</p>

                          <Gap v={1} />
                        </div>

                        {errorMessage ? <div className="color-e5383b mb-4 text-center">{errorMessage}</div> : null}

                        {!userAccounts.find((account) => account.accountNumber === accountNumber) ? (
                          <div className="text-center">
                            <label htmlFor="checkbox" className="custom-checkbox mb-2">
                              <input
                                type="checkbox"
                                id="checkbox"
                                name="terms"
                                checked={saveAccount}
                                onChange={(ev) => setSaveAccount(ev.target.checked)}
                              />
                              <span className="checkmark"></span>
                              Save bank account
                            </label>
                          </div>
                        ) : null}

                        <Gap v={2} />

                        <Button
                          loading={loading}
                          disabled={!amount || !(accountNumber.length >= 9)}
                          text={loading ? 'Please wait...' : 'Continue'}
                          onClick={() => {
                            if (!balanceAndLimitsCheck()) {
                              return;
                            }

                            if (!accountName || !bankName) {
                              lookupBankAccount(bankId, accountNumber);
                            } else {
                              setStage('CONFIRMATION');
                            }
                          }}
                        />
                      </div>
                    </Slider>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
