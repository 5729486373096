import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import * as swfcApi from '../../api/swiftcards';
import * as kycApi from '../../api/kyc';
import { getExpiryFromNow, handleRequestErrors } from '../../utils';
import { LOCAL_APP_TOKEN } from '../../constants';
import { saveState } from '../../localstorage';
import * as miscActions from '../../actions/misc';
import * as kycActions from '../../actions/kyc';
import { RatesInfo } from '../../components/RatesInfo';
import apple from '../../assets/apple.svg';
import androidSVG from '../../assets/andriod.svg';
import { Header } from '../../components/header';
import Ios from '../../components/homescreenSteps/Ios';
import Andriod from '../../components/homescreenSteps/Andriod';
import Gap from '../../components/Gap';
import { route } from '../../routes';
import './styles.scss';
import { Footer } from '../../components/Footer';
import mixpanel from '../../mixpanel';

const GoogleAuthBtn = ({ referrerCode }: { referrerCode?: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleGoogleSignup = (token: string) => {
    setLoading(true);

    swfcApi
      .authExternal(token, 'GMAIL', referrerCode)
      .then(async (response) => {
        saveState(LOCAL_APP_TOKEN, {
          ...response,
          expiresIn: getExpiryFromNow(response.expiresIn),
        });

        const kycData = await kycApi.getPersonalInfo();

        dispatch(kycActions.setIndividualKycInfo(kycData));
        dispatch(miscActions.updateMiscSettings({ token: response }));
        navigate(kycData.status === 'VERIFIED' ? route.home : route.kyc, { replace: true });
      })
      .catch(handleRequestErrors)
      .finally(() => setLoading(false));
  };

  const googleSignup = useGoogleLogin({
    prompt: 'consent',
    onSuccess: ({ access_token: token }) => handleGoogleSignup(token),
    onError: (err) => console.log('Signup failed: ', err),
  });

  return (
    <a
      className="btn btn-google-login color-0f0f0f"
      onClick={() => {
        if (loading) {
          return;
        }

        mixpanel.track('Click-Button-GoogleSignup');
        googleSignup();
      }}
    >
      <img src={require('../../assets/google-icon.png')} alt="" height={25} /> <Gap h={0.5} />{' '}
      {loading ? 'Please wait...' : 'Continue with Google'}
    </a>
  );
};

export const Auth = () => {
  const { referrerCode }: any = useParams();
  const [appShortcutView, setAppShortcutView] = useState<'ANDROID' | 'IOS'>();

  useEffect(() => {
    mixpanel.track('View-Auth');
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}>
      <div className="app-container">
        <div className="app-wrap">
          <div className="auth-container">
            <div className="app-inner">
              {appShortcutView ? <Header backButton authenticated={false} /> : null}

              <Gap v={2} />

              {!appShortcutView ? (
                <>
                  <div className="hero-container">
                    <img src={require('../../assets/lasercards.png')} alt="" />
                  </div>
                  <Gap v={2} />
                  <RatesInfo />
                  <Gap v={2} />
                </>
              ) : null}

              <div className="hero-caption max-w-350x margin-auto_center">
                {!appShortcutView ? (
                  <>
                    <h2 className="text-center">Get Your LaserCards Virtual Dollar Card</h2>

                    <p className="font-weight-default mt-3">LaserCards are fast, reliable and work pretty much everywhere.</p>

                    <Gap v={1} />

                    <GoogleAuthBtn referrerCode={referrerCode} />
                    <Gap v={2} />
                  </>
                ) : null}

                <div className="app-homescreen-view">
                  <p>Add LaserCards to Your Phone</p>

                  {!appShortcutView ? (
                    <div className="app-tab-navs">
                      <a
                        onClick={() => {
                          mixpanel.track('Click-Button-AddToPhone', {
                            platform: 'iOS',
                          });

                          setAppShortcutView('IOS');
                        }}
                      >
                        <img src={apple} alt="apple" height={17} />
                        <span>For iOS</span>
                      </a>

                      <a
                        onClick={() => {
                          mixpanel.track('Click-Button-AddToPhone', {
                            platform: 'Android',
                          });

                          setAppShortcutView('ANDROID');
                        }}
                      >
                        <img src={androidSVG} alt="android" height={16} />
                        <span>For Android</span>
                      </a>
                    </div>
                  ) : (
                    <Slider
                      dots={true}
                      speed={500}
                      arrows={false}
                      infinite={false}
                      slidesToShow={1}
                      slidesToScroll={1}
                      adaptiveHeight={true}
                      customPaging={(i) => {
                        const NavOptions: { [x: number]: string } = { 0: 'For iOS', 1: 'For Android' };

                        return (
                          <div className="tab-nav" key={`${NavOptions[i]}-${i}`}>
                            <img src={i === 0 ? apple : androidSVG} alt="apple" height={17} />
                            <span>{NavOptions[i]}</span>
                          </div>
                        );
                      }}
                    >
                      <div className="app-tab app-ios-tab">
                        <Ios />
                      </div>

                      <div className="app-tab app-android-tab">
                        <Andriod />
                      </div>
                    </Slider>
                  )}
                </div>
              </div>
            </div>

            {!appShortcutView ? <Footer /> : null}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};
