import React, { useEffect, useState } from 'react';
import moment from 'moment';
import copy from 'clipboard-copy';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Header } from '../../components/header';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../typings/reducer';
import { formatCurrency } from '../../utils';
import { loadState, saveState } from '../../localstorage';
import { CardTransactionDto } from '../../typings/cards';
import * as swfcApi from '../../api/swiftcards';
import Gap from '../../components/Gap';
import './styles.scss';
import twitter from '../../assets/twitter.svg';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import statArr from '../../assets/stat-arr.svg';
import mixpanel from '../../mixpanel';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { route } from '../../routes';

export const HomeStart = () => {
  const [helperMessage, setHelperMessage] = useState<string>();
  const { user, accounts, rates, cards } = useSelector((state: ReducerStates) => state);

  let helperTs: NodeJS.Timeout;
  const localCurrency = user.currency || 'NGN';
  const cardCurrency = cards.currencies?.find((curr) => curr.code === 'USD');
  const buyRate = Number(rates.find((rate) => rate.fromCurrency === localCurrency && rate.toCurrency === cardCurrency?.code)?.rate || 0);
  const account = accounts.depositChannel?.methods[0]?.account;

  useEffect(() => {
    setHelperMessage('');

    if (helperTs) {
      clearTimeout(helperTs);
    }

    helperTs = setTimeout(() => {
      if (!account) {
        setHelperMessage('This is taking a little longer than usual. Please swipe this page to check if it has been generated.');
      } else {
        setHelperMessage('');
      }
    }, 5000);
  }, [accounts.depositChannel]);

  useEffect(() => {
    mixpanel.track('View-Home-Start');
  }, []);

  const copyText = (text: string) => {
    toast.dark('Copied!', { position: 'bottom-center' });
    copy(text);
  };

  return (
    <div className="app-container">
      <div className="app-wrap">
        <div className="home-container">
          <div className="app-inner">
            <Header />
          </div>

          <div className="home-illus"></div>

          <div className="app-inner">
            <Gap v={3} />

            {account ? (
              <>
                <div className="text-center">
                  To create your card, you'll need to make a minimum deposit of{' '}
                  <b>{formatCurrency((cardCurrency?.creationFee! * 2) / buyRate + 50, localCurrency)}</b>, a card creation fee of{' '}
                  <b>{formatCurrency(cardCurrency?.creationFee!, 'USD')}</b> will be deducted from your card balance.
                </div>

                {account.type === 'BANK_TRANSFER' ? (
                  <div className="account-info text-center">
                    <h1 className="cursor-pointer tap-effect" onClick={() => copyText(account.accountNumber)}>
                      {account.accountNumber} <span className="icon-copy"></span>
                    </h1>
                    <p className="details">
                      <span className="info">{account.bankName}</span>
                      <span className="separator"></span>
                      <span className="info">
                        {user.firstName} {user.lastName}
                      </span>
                    </p>
                  </div>
                ) : null}

                <div className="note warning">
                  Deposits from an account not in your name will be reversed. Your card will be created immediately your deposit is
                  received.
                </div>

                <div className="max-w-300x margin-auto_center">
                  <p className="text-center color-d4d4d4 mt-5" style={{ opacity: 0.5, fontSize: '14px' }}>
                    LaserCards virtual accounts are powered by{' '}
                    <a href="https://mypasspoint.com" target="_blank" rel="noopener noreferrer">
                      Passpoint Technologies
                    </a>
                  </p>
                </div>
              </>
            ) : (
              <div className="loading-area text-center">
                <span className="icon-loading"></span>
                {helperMessage ? <div className="pt-3 max-w-350x margin-auto_center">{helperMessage}</div> : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TransactionRow = ({ txn }: { txn: CardTransactionDto }) => {
  const getTxMeta = (name: string) => txn.metadata.find((meta) => meta.name === name)?.value;

  const transactionConfig = getTxMeta('transactionConfig');
  const transactionType = getTxMeta('transactionType');

  const isFundingTx = transactionType === 'FUNDING' || /funding/.test(txn.memo);
  const isWithdrawalTx = transactionType === 'WITHDRAWAL' || /withdrawal/.test(txn.memo);
  const isChargeTx = transactionType === 'CHARGE' || /charge/.test(txn.memo);
  const isReferralTx = transactionConfig === 'CARD_REFERRAL_BONUS';

  // const wasCharged = transactionType === 'CHARGE';
  const wasDeclined = transactionType === 'DECLINED';
  const wasReversed = transactionType === 'REVERSAL';
  const wasRefunded = transactionType === 'REFUNDED';

  // prettier-ignore
  const creationFee =
    txn.metadata?.find((meta) => meta.name === 'transactionConfig')?.value === 'CARD_CREATION' ?
      Number(txn.metadata?.find((meta) => meta.name === 'creationFee')?.value || 0) : 0;

  const getMerchantInfo = () => `${getTxMeta('merchantName')} (${[getTxMeta('merchantCity'), getTxMeta('merchantCountry')].join(', ')})`;

  const getFundingInfo = () => getTxMeta('narration') || 'Bank Transfer';

  const getWithdrawalInfo = () =>
    ` TO ${getTxMeta('bankName') || ''} / ${getTxMeta('accountName') || ''} / ${getTxMeta('accountNumber') || ''}`;

  return (
    <li
      key={txn.id}
      className={
        // prettier-ignore
        wasDeclined ? 'tx-declined' :
          txn.status === 'PENDING' ? 'tx-pending' :
            wasReversed || wasRefunded ? 'tx-reverserd-refunded' :
              txn.type === 'CREDIT' ? 'tx-in' : 'tx-out'
      }
    >
      <div className="left">
        <div className="label">
          {
            // prettier-ignore
            isReferralTx ? 'Referral Bonus' :
              transactionType === 'CARD_CREATION' ? 'Card Creation Fee' :
                isFundingTx || txn.type === 'CREDIT' ? 'Card Top-Up' :
                  isWithdrawalTx ? 'Card Withdrawal' :
                    isChargeTx || txn.type === 'DEBIT' ? 'Card Charge' : ''
          }
        </div>
        <div className="info">
          {isFundingTx && !isReferralTx ? getFundingInfo() : null}
          {isWithdrawalTx && !isReferralTx ? getWithdrawalInfo() : null}
          {isChargeTx ? getMerchantInfo() : null}
          {wasReversed || wasRefunded ? getMerchantInfo() : null}
          {wasDeclined ? getMerchantInfo() : null}
        </div>
        <div className="value">{moment.utc(txn.createdAt, 'ddd MMM D Y HH:mm:s').format('MMM D, Y hh:mm')}</div>
      </div>

      <div className="right">
        <span className="currency">{txn.currency}</span>
        <span className="amount">
          {
            // prettier-ignore
            (
              txn.amount + creationFee
            ).toLocaleString('en')
          }
        </span>
      </div>
    </li>
  );
};

export const HomeMain = () => {
  const navigate = useNavigate();
  const [privacy, setPrivacy] = useState(false);
  const [transactions, setTransactions] = useState<CardTransactionDto[]>([]);
  const { user, accounts, cards, rates, misc } = useSelector((state: ReducerStates) => state);

  const card = cards?.cards?.find((card) => ['ACTIVE', 'FROZEN'].includes(card.status))!;
  const localCurrency = user.currency || 'NGN';
  const account = accounts.depositChannel?.methods[0]?.account;
  const sellRate = Number(rates.find((rate) => rate.toCurrency === localCurrency && rate.fromCurrency === 'USD')?.rate || 0);

  const NavOptions: { [x: number]: string } = {
    0: 'Balance',
    1: 'Card Details',
    2: 'Transactions',
  };

  useEffect(() => {
    const privacyState = loadState('privacy');
    setPrivacy(privacyState);
  }, []);

  useEffect(() => {
    mixpanel.track('View-Home-Main');
  }, []);

  useEffect(() => {
    if (!card) {
      return;
    }

    swfcApi
      .getCardTransactions({
        page: 1,
        size: 30,
        cardId: card.id,
      })
      .then((response) => {
        setTransactions(response.records);
      });
  }, [card]);

  const updatePrivacy = () => {
    setPrivacy(!privacy);
    saveState('privacy', !privacy);
  };

  const copyText = (text: string) => {
    toast.dark('Copied!', { position: 'bottom-center' });
    copy(text);
  };

  return (
    <div className="app-container">
      <div className="app-wrap">
        <div className="home-container">
          <div className="app-inner">
            <Header cardId={card.id} />

            <div className={`card-illustration mt-4 ${misc.cardAction ? 'loading' : ''}`}>
              <div className="card-wrap">
                <div className="card-head">
                  <div className="logo-area">
                    <img src={require('../../assets/logo.png')} alt="" height={25} />
                  </div>

                  <div className="card-scheme">
                    <span className={card.scheme === 'VISA' ? 'icon-visa' : 'icon-mastercard'}></span>
                  </div>
                </div>

                <div className="card-info card-center">
                  <div className="wrap">
                    <p>Name</p>
                    <h5>
                      {card.holder.firstName} {card.holder.lastName}
                    </h5>
                  </div>
                </div>

                <div className="card-base">
                  <div className="wrap">
                    <div className="card-info">
                      <p>Card Number</p>
                      <h5>**** **** **** {card.pan.slice(-4)}</h5>
                    </div>

                    <div className="card-info">
                      <p>Expiry</p>
                      <h5>{card.expiry.match(/.{1,2}/g)?.join('/')}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Gap v={1} />

            <div className="card-decline-info" onClick={() => navigate(route.statsDecline.replace(':cardId', card.id))}>
              <img src={require('../../assets/warning-2.png')} height={25} alt="" />
              <div className="content">
                <p className="font-weight-500">Learn more about your card decline rate</p>
                <a className="color-5e2bc9 font-weight-600">
                  See my stats <img src={statArr} alt="" />
                </a>
              </div>
            </div>

            <div className="tab-wrapper">
              <Slider
                dots={true}
                infinite={false}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                adaptiveHeight={true}
                customPaging={(i) => <div className="tab-nav font-weight-600">{NavOptions[i]}</div>}
              >
                <div className="tab-content balance-tab">
                  <Gap v={2} />

                  <div className="max-w-350x margin-auto_center">
                    <div className="text-center margin-auto_center max-w-320x cursor-pointer">
                      <a className="color-ffffff" onClick={() => updatePrivacy()}>
                        My Balance <span className="icon-eye"></span>
                      </a>
                    </div>
                    <div className="balance-info text-center">
                      <h1 className="mt-1">{privacy ? '****' : formatCurrency(card.balance, card.currency)}</h1>
                      {!privacy ? (
                        <p className="equiv">
                          <span className="icon-equiv"></span> &nbsp;{formatCurrency(card.balance * sellRate, localCurrency)}
                        </p>
                      ) : null}
                    </div>

                    <hr />

                    <div className="text-center margin-auto_center max-w-320x">Fund Your Card Via Bank Transfer</div>

                    {account?.type === 'BANK_TRANSFER' ? (
                      <div className="account-info text-center">
                        <h1
                          className="mt-1 cursor-pointer tap-effect"
                          onClick={() => {
                            mixpanel.track('Click-AccountNumber-Copy');
                            copyText(account.accountNumber);
                          }}
                        >
                          {account.accountNumber} <span className="icon-copy"></span>
                        </h1>
                        <p className="details">
                          <span className="info">{account.bankName}</span>
                          <span className="separator"></span>
                          <span className="info">
                            {user.firstName} {user.lastName}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div className="loading-area text-center my-3">
                        <span className="icon-loading"></span>
                      </div>
                    )}
                  </div>

                  <div className="p-3">
                    <div className="note warning">Please note that, deposits from an account not in your name might be reversed.</div>

                    <div className="max-w-300x margin-auto_center">
                      <p className="text-center color-d4d4d4 mt-5" style={{ opacity: 0.5, fontSize: '14px' }}>
                        LaserCards virtual accounts are powered by{' '}
                        <a
                          href="https://mypasspoint.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => mixpanel.track('Click-Link-PasspointTech')}
                        >
                          Passpoint Technologies
                        </a>
                      </p>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <a
                        className="cursor-pointer"
                        href="https://twitter.com/lasercardsco"
                        onClick={() => mixpanel.track('Click-Link-Twitter')}
                      >
                        <img src={twitter} alt="twitter" />
                      </a>
                      <Gap h={2} />

                      <a
                        className="cursor-pointer"
                        href="https://web.facebook.com/profile.php?id=100092591473238"
                        onClick={() => mixpanel.track('Click-Link-Facebook')}
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                      <Gap h={2} />

                      <a
                        className="cursor-pointer"
                        href="https://www.instagram.com/lasercardsco/"
                        onClick={() => mixpanel.track('Click-Link-Instagram')}
                      >
                        <img src={instagram} alt="instagram" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <ul className="card-details">
                    <li>
                      <div className="left">
                        <div className="label">Card Type</div>
                        <div className="value">{card.scheme}</div>
                      </div>
                      <div className="right">
                        <span className={card.scheme === 'MASTERCARD' ? 'icon-mastercard' : 'icon-visa'}></span>
                      </div>
                    </li>

                    <li>
                      <div className="left cursor-pointer" onClick={() => copyText(card.pan)}>
                        <div className="label">Card Number</div>
                        <div className="value">
                          {privacy ? `**** **** **** ${card.pan.slice(-4)}` : card.pan.match(/.{1,4}/g)?.join(' ')}
                        </div>
                      </div>
                      <div className="right">
                        <span className="icon-eye icon-big-area cursor-pointer" onClick={() => updatePrivacy()}></span>
                        <Gap h={0.2} />
                        <span className="icon-copy icon-big-area cursor-pointer tap-effect" onClick={() => copyText(card.pan)}></span>
                      </div>
                    </li>

                    <li>
                      <div className="left cursor-pointer" onClick={() => copyText(card.expiry.match(/.{1,2}/g)?.join('/') ?? '')}>
                        <div className="label">Expiry Date</div>
                        <div className="value">{privacy ? '**/**' : card.expiry.match(/.{1,2}/g)?.join('/')}</div>
                      </div>
                      <div className="right">
                        <span className="icon-eye icon-big-area cursor-pointer" onClick={() => updatePrivacy()}></span>
                        <Gap h={0.2} />
                        <span
                          className="icon-copy icon-big-area cursor-pointer tap-effect"
                          onClick={() => copyText(card.expiry.match(/.{1,2}/g)?.join('/') ?? '')}
                        ></span>
                      </div>
                    </li>

                    <li>
                      <div className="left cursor-pointer" onClick={() => copyText(card.cvv)}>
                        <div className="label">CVV/CVC</div>
                        <div className="value">{privacy ? '***' : card.cvv}</div>
                      </div>
                      <div className="right">
                        <span className="icon-eye icon-big-area cursor-pointer" onClick={() => updatePrivacy()}></span>
                        <Gap h={0.2} />
                        <span className="icon-copy icon-big-area cursor-pointer tap-effect" onClick={() => copyText(card.cvv)}></span>
                      </div>
                    </li>

                    <li>
                      <div className="left cursor-pointer" onClick={() => copyText(card.holder.address)}>
                        <div className="label">Address</div>
                        <div className="value">{card.holder.address}</div>
                      </div>
                      <div className="right">
                        <span
                          className="icon-copy icon-big-area cursor-pointer tap-effect"
                          onClick={() => copyText(card.holder.address)}
                        ></span>
                      </div>
                    </li>

                    <li>
                      <div className="left cursor-pointer" onClick={() => copyText(card.holder.city)}>
                        <div className="label">City</div>
                        <div className="value">{card.holder.city}</div>
                      </div>
                      <div className="right">
                        <span
                          className="icon-copy icon-big-area cursor-pointer tap-effect"
                          onClick={() => copyText(card.holder.city)}
                        ></span>
                      </div>
                    </li>

                    <li>
                      <div className="left cursor-pointer" onClick={() => copyText(card.holder.state)}>
                        <div className="label">State</div>
                        <div className="value">{card.holder.state}</div>
                      </div>
                      <div className="right">
                        <span
                          className="icon-copy icon-big-area cursor-pointer tap-effect"
                          onClick={() => copyText(card.holder.state)}
                        ></span>
                      </div>
                    </li>

                    <li>
                      <div className="left cursor-pointer" onClick={() => copyText(card.holder.zipCode)}>
                        <div className="label">Zip Code</div>
                        <div className="value">{card.holder.zipCode}</div>
                      </div>
                      <div className="right">
                        <span
                          className="icon-copy icon-big-area cursor-pointer tap-effect"
                          onClick={() => copyText(card.holder.zipCode)}
                        ></span>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="tab-content">
                  <ul className="card-transactions">
                    {transactions.map((txn) => (
                      <TransactionRow key={txn.id} txn={txn} />
                    ))}
                  </ul>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Home = () => {
  const {
    cards: { cards },
    misc,
  } = useSelector((state: ReducerStates) => state);

  const card = cards?.find((card) => ['ACTIVE', 'FROZEN'].includes(card.status));

  return !misc.gottenCards ? (
    <div className="app-container">
      <div className="app-wrap">
        <div className="global-loading-container"></div>
      </div>
    </div>
  ) : !card ? (
    <HomeStart />
  ) : (
    <HomeMain />
  );
};
