import React from 'react';
import one from '../../assets/hs-steps-ios/1.svg';
import two from '../../assets/hs-steps-ios/2.svg';
import three from '../../assets/hs-steps-ios/3.svg';
import four from '../../assets/hs-steps-ios/4.svg';
import Gap from '../Gap';
import './styles.scss';

const Ios = () => {
  return (
    <>
      <Gap v={2} />

      <ul className="hs-list-steps">
        <li data-n="01">
          <p>
            Open LaserCards on any browser, and locate the <span className="text-pink">Share</span> Icon
          </p>

          <div className="img-holder">
            <img src={one} alt="step1" />
          </div>
        </li>

        <li data-n="02">
          <p>
            Find the <span className="text-pink">"Add to Home Screen"</span>option on the pop-up menu
          </p>

          <div className="img-holder">
            <img src={two} alt="step2" />
          </div>
        </li>

        <li data-n="03">
          <p>
            Give it your preferred name and click the <span className="text-pink"> “Add”</span> button, and you’re done
          </p>

          <div className="img-holder">
            <img src={three} alt="step3-1" />
          </div>

          <div className="img-holder">
            <img src={four} alt="step3-2" />
          </div>
        </li>
      </ul>
    </>
  );
};

export default Ios;
