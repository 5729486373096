import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Header } from '../../components/header';
import { ReducerStates } from '../../typings/reducer';
import * as swfcApi from '../../api/swiftcards';
import { CardDeclinePlatforms, CardDeclineStat } from '../../typings/cards';
import Gap from '../../components/Gap';
import './styles.scss';

const DeclineStats = () => {
  const { cardId }: any = useParams();
  const { cards } = useSelector((state: ReducerStates) => state);
  const card = cards?.cards?.find((card) => card.id === cardId)!;
  const [declineStats, setDeclineStats] = useState<CardDeclineStat>();
  const [declinePlatforms, setDeclinePlatforms] = useState<CardDeclinePlatforms>();

  const NavOptions: { [x: number]: string } = {
    0: 'Decline Stats',
    1: 'Top Platforms',
  };

  useEffect(() => {
    if (!card?.id) {
      return;
    }

    Promise.all([swfcApi.getDeclineStats(card.id), swfcApi.getDeclinePlatforms(card.id)]).then(([_declineStats, _getDeclinePlatforms]) => {
      setDeclineStats(_declineStats);
      setDeclinePlatforms(_getDeclinePlatforms);
    });
  }, [card?.id]);

  return (
    <div className="app-container">
      <div className="app-wrap">
        <div className="home-container">
          <div className="app-inner">
            <Header cardId={card?.id} backButton={true} />

            <div className="tab-wrapper">
              <Slider
                dots={true}
                infinite={false}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                adaptiveHeight={true}
                customPaging={(i) => <div className="tab-nav font-weight-600">{NavOptions[i]}</div>}
              >
                <div className="tab-content stats-tab">
                  <ul className="stat-wrap">
                    <li className="stat-data">
                      <p className="title">Today</p>
                      <p className="sub">
                        {declineStats?.currentDay} <span>declines today</span>
                      </p>
                    </li>
                    <li className="stat-data">
                      <p className="title">This Month</p>
                      <p className="sub">
                        {declineStats?.currentMonth} <span>declines this month</span>
                      </p>
                    </li>
                    <li className="stat-data">
                      <p className="title">Last Week</p>
                      <p className="sub">
                        {declineStats?.currentWeek} <span>declines last week</span>
                      </p>
                    </li>
                    <li className="stat-data">
                      <p className="title">Last Month</p>
                      <p className="sub">
                        {declineStats?.previousMonth} <span>declines last month</span>
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="tab-content platforms-tab">
                  <ul className="stat-wrap">
                    {declinePlatforms?.platforms.map((platform) => (
                      <li key={platform.name} className="stat-platform">
                        <p className="title">{platform.name}</p>
                        {platform.stat.currentWeek ? (
                          <p className="sub">
                            {platform.stat.currentWeek} <span>this week</span>
                          </p>
                        ) : platform.stat.currentDay ? (
                          <p className="sub">
                            {platform.stat.currentDay} <span>today</span>
                          </p>
                        ) : platform.stat.currentMonth ? (
                          <p className="sub">
                            {platform.stat.currentMonth} <span>this month</span>
                          </p>
                        ) : platform.stat.previousMonth ? (
                          <p className="sub">
                            {platform.stat.previousMonth} <span>last month</span>
                          </p>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
              </Slider>
            </div>

            <Gap v={3} />

            <div className="note warning">
              Please note that too many declines may result in your card getting deactivated and you having to create a new one
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclineStats;
