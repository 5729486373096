import React, { useEffect, useState } from 'react';
import Gap from '../Gap';

export interface ISelectProps {
  options?: { value: string; label: string }[];
  label?: string;
  info?: string;
  gapbottom?: number;
}

export const Select2 = (
  props: ISelectProps & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
) => {
  const { ref, className, options, onChange = () => {}, required = false, label, placeholder, info, gapbottom, ...restProps } = props;
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <div className="form-group">
      {label && (
        <>
          <label htmlFor={props.id || ''}>{label}</label>
          <Gap v={0.5} />
        </>
      )}

      <div className="sel-custom">
        <select
          ref={ref}
          required={required}
          className={`form-control ${className || ''}`}
          value={value}
          {...restProps}
          onChange={(ev) => {
            setValue(ev.target.value);
            onChange(ev);
          }}
        >
          {placeholder ? <option value="">{placeholder}</option> : null}

          {options &&
            options.map(({ value, label }, i) => (
              <option key={value + i} value={value}>
                {label}
              </option>
            ))}
        </select>
      </div>

      {info && (
        <>
          <Gap v={0.3} />
          <small className="text-muted">{info}</small>
        </>
      )}

      {gapbottom ? <Gap v={gapbottom} /> : null}
    </div>
  );
};
