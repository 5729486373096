import React from 'react';
import Gap from '../Gap';

interface InputProps {
  label?: string;
  info?: string;
  gapbottom?: number;
}

export const Input = (props: InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const { label, info, gapbottom, type, className, ...restProps } = props;

  return (
    <div className="form-group">
      {label && (
        <label className="mb-2" htmlFor={props.id || ''}>
          {label}
        </label>
      )}

      <input className={`form-control ${className}`} type={type || 'text'} {...restProps} />

      {info && <small className="text-muted">{info}</small>}

      {gapbottom ? <Gap v={gapbottom} /> : null}
    </div>
  );
};
