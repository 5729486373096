import { CardsAction, CardsState } from '../typings/cards';

const initialState: CardsState = {
  cards: [],
  currencies: [],
};

export default (state = initialState, action: CardsAction): Partial<CardsState> => {
  switch (action.type) {
    case 'SET_CARDS':
      return {
        ...state,
        cards: action.cards,
      };
    case 'UPDATE_CARD':
      return {
        ...state,
        cards: state.cards?.map((card) => {
          if (card.id === action.cardId) {
            return {
              ...card,
              ...action.card,
            };
          }

          return card;
        }),
      };
    case 'SET_CURRENCIES':
      return {
        ...state,
        currencies: action.currencies,
      };
    default:
      return state;
  }
};
