import { ListsState, ListsAction } from '../typings/lists';

const defaultState: ListsState = {
  countries: [],
};

export default (state = defaultState, action: ListsAction): ListsState => {
  switch (action.type) {
    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: action.countries,
      };
    default:
      return state;
  }
};
