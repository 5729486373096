import mixpanel from 'mixpanel-browser';

// prettier-ignore
mixpanel.init(
  process.env.REACT_APP_VARIANT === 'PRODUCTION' ? '287d1164c4ffd980564bac42e57dfb94' : '330b03a27566cc8bd97c71cc83d2035e',
  { debug: false },
);

mixpanel.set_config({ persistence: 'localStorage' });

export default mixpanel;
