import React, { useEffect } from 'react';
import Gap from '../../components/Gap';
import checkIcon from '../../assets/check.svg';
import { Footer } from '../../components/Footer';
import mixpanel from '../../mixpanel';

const GettingStarted = () => {
  const content = [
    'LaserCards allows you to sign up with your Google account and your BVN or NIN',
    'We’d send you an OTP both to your registered email and phone number',
  ];

  useEffect(() => {
    mixpanel.track('View-Getting-Started');
  }, []);

  return (
    <div className="app-container">
      <div className="app-wrap">
        <div className="home-container">
          <div className="app-inner">
            <div className="app-head app-head-kyc">
              <a className="back" onClick={() => history.go(-1)}></a>

              <img src={require('../../assets/logo.png')} alt="" height={40} />

              <div className="max-w-350x margin-auto_center">
                <p>Getting Started With LaserCards</p>
              </div>
            </div>

            <Gap v={2} />

            <div>
              <div className="mt-3">
                {content.map((text, index) => (
                  <div key={index} className="d-flex mb-3">
                    <img src={checkIcon} alt="info" height={20} className="mt-1" />
                    <Gap h={1} />
                    <p className="p--thin">{text}</p>
                  </div>
                ))}
              </div>

              <div className="d-flex mb-3">
                <img src={checkIcon} alt="info" height={20} className="mt-1" />
                <Gap h={1} />
                <p className="p--thin">
                  If you don’t get your OTP, you can use the<span className="text-pink"> “Resend”</span> option or contact support for
                  manual verification.
                </p>
              </div>

              <div className="d-flex mb-5">
                <img src={checkIcon} alt="info" height={20} className="mt-1" />
                <Gap h={1} />
                <p className="p--thin">
                  You can reach out to us anytime on <a href="mailto:support@lasercards.co">support@lasercards.co</a> or visit our
                  <span className="text-pink">
                    {' '}
                    <a href="https://lasercards.super.site/help-faqs">FAQ</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <Gap v={2} />

        <Footer />
      </div>
    </div>
  );
};

export default GettingStarted;
