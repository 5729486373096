import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ACCOUNT_REFRESH_INTERVAL, LOCAL_APP_TOKEN, LOCAL_APP_VERSION, RATE_REFRESH_INTERVAL } from './constants';
import { route } from './routes';
import { loadState, loadStateWithExpiry, saveState } from './localstorage';
import 'react-toastify/dist/ReactToastify.min.css';
import * as swfcApi from './api/swiftcards';
import * as listsApi from './api/lists';
import * as kycApi from './api/kyc';
import * as userActions from './actions/user';
import * as miscActions from './actions/misc';
import * as listsActions from './actions/lists';
import * as kycActions from './actions/kyc';
import * as cardsActions from './actions/cards';
import * as ratesActions from './actions/rates';
import * as accountsActions from './actions/accounts';
import { Auth } from './modules/Auth';
import { Kyc } from './modules/Kyc';
import { Home } from './modules/home';
import { useSelector } from 'react-redux';
import { ReducerStates } from './typings/reducer';
import { handleRequestErrors } from './utils';
import { Withdraw } from './modules/Withdraw';
import PullToRefresh from 'react-simple-pull-to-refresh';
import Referral from './modules/Referral';
import GettingStarted from './modules/GettingStarted';
import DeclineStats from './modules/DeclineStat';
import mixpanel from './mixpanel';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const location = useLocation();

  const getToken = () => loadStateWithExpiry(LOCAL_APP_TOKEN);

  if (!getToken()) {
    mixpanel.reset();
    return <Navigate to={route.auth} replace state={{ from: location }} />;
  }

  return <>{props.children}</>;
};

export const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(true);
  const [ratesTs, setRatesTs] = useState<NodeJS.Timer>();
  const [accountTs, setAccountTs] = useState<NodeJS.Timer>();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [fetchingRates, setFetchingRates] = useState(false);
  const { user, misc, kyc } = useSelector((state: ReducerStates) => state);

  window.addEventListener('online', () => setIsOnline(true));
  window.addEventListener('offline', () => setIsOnline(false));

  const getExchangeRates = async () => {
    if (fetchingRates || isRefreshing || !navigator.onLine || !isOnline) {
      return;
    }

    setFetchingRates(true);

    try {
      const localCurrency = user.currency || 'NGN';
      const rates = await Promise.all([swfcApi.getExchangeRate(localCurrency, 'USD'), swfcApi.getExchangeRate('USD', localCurrency)]);
      rates.forEach((rate) => dispatch(ratesActions.addRate(rate)));
      dispatch(miscActions.updateMiscSettings({ gottenRates: true }));
    } finally {
      setFetchingRates(false);
    }
  };
  const getActiveUserInfo = async () => {
    if (!misc.token || isRefreshing) {
      return;
    }

    try {
      const user = await swfcApi.getActiveUser();

      dispatch(userActions.setUser(user));
      dispatch(miscActions.updateMiscSettings({ gottenActiveUser: true }));

      const userKyc = await kycApi.getPersonalInfo();
      dispatch(kycActions.setIndividualKycInfo(userKyc));

      mixpanel.identify(user.email);
      mixpanel.people.set({
        $email: user.email,
        $name: `${user.firstName} ${user.lastName}`,
        $kycStatus: userKyc.status,
        $restricted: user.restricted,
      });

      if (userKyc.status !== 'VERIFIED') {
        navigate(route.kyc, { replace: true });
      }
    } catch (err) {
      handleRequestErrors(err);
    }
  };
  const getUserCards = async () => {
    if (!misc.token || isRefreshing || kyc.individual?.status !== 'VERIFIED') {
      return;
    }

    try {
      const response = await swfcApi.getCards();
      dispatch(cardsActions.setCards(response.records));
      dispatch(miscActions.updateMiscSettings({ gottenCards: true }));
    } catch (err) {
      handleRequestErrors(err);
    }
  };
  const getDepositChannel = async () => {
    if (!misc.token || isRefreshing || kyc.individual?.status !== 'VERIFIED') {
      return;
    }

    let channel;

    try {
      channel = await swfcApi.getDepositChannel();
      dispatch(accountsActions.setDepositChannel(channel));
      dispatch(miscActions.updateMiscSettings({ gottenDepositAccounts: true }));
    } catch (err) {
      handleRequestErrors(err);
    }

    clearTimeout(accountTs);

    if ((channel?.methods.length || 0) === 0) {
      setAccountTs(
        setTimeout(() => {
          if (isRefreshing) {
            return;
          }

          getDepositChannel();
        }, ACCOUNT_REFRESH_INTERVAL),
      );
    }
  };

  useEffect(() => {
    swfcApi.getVersion().then(({ version }) => {
      const currentVersion = loadState(LOCAL_APP_VERSION);

      if (currentVersion !== version) {
        saveState(LOCAL_APP_VERSION, version);

        if ('serviceWorker' in navigator) {
          caches.keys().then(function (cacheNames) {
            cacheNames.forEach(function (cacheName) {
              caches.delete(cacheName);
            });
          });
        }

        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (misc.gottenCurrencies) {
      return;
    }

    swfcApi
      .getSupportedCurrencies()
      .then(({ currencies }) => {
        dispatch(cardsActions.setCurrencies(currencies));
        dispatch(miscActions.updateMiscSettings({ gottenCurrencies: true }));
      })
      .catch(handleRequestErrors);
  }, []);

  useEffect(() => {
    if (!misc.gottenCurrencies || misc.gottenRates) {
      return;
    }

    getExchangeRates();

    if (!ratesTs) {
      setRatesTs(
        setInterval(() => {
          if (isRefreshing) {
            return;
          }

          getExchangeRates();
        }, RATE_REFRESH_INTERVAL),
      );
    }
  }, [misc.gottenCurrencies, user]);

  useEffect(() => {
    if (!misc.token) {
      return;
    }

    if (!misc.gottenActiveUser) {
      getActiveUserInfo();
    }

    if (!misc.gottenLocations) {
      listsApi
        .getCountries()
        .then((response) => {
          dispatch(listsActions.setCountries(response.countries));
          dispatch(miscActions.updateMiscSettings({ gottenLocations: true }));
        })
        .catch(() => void 0);
    }

    if (!misc.gottenCards && kyc.individual?.status === 'VERIFIED') {
      getUserCards();
    }
  }, [misc.token, kyc]);

  useEffect(() => {
    if (kyc.individual?.status !== 'VERIFIED' || misc.gottenDepositAccounts) {
      return;
    }

    getDepositChannel();
  }, [kyc]);

  return (
    <PullToRefresh
      resistance={5}
      maxPullDownDistance={95}
      onRefresh={async () => {
        if (isRefreshing) {
          return;
        }

        setIsRefreshing(true);

        try {
          // prettier-ignore
          await Promise.all([
            getUserCards(),
            getExchangeRates(),
            getActiveUserInfo(),
            getDepositChannel(),
          ]);
        } finally {
          setIsRefreshing(false);
        }
      }}
    >
      <>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          toastClassName="custom-toast-container"
          closeButton={<></>}
          position="top-center"
        />

        <Routes>
          <Route path={route.auth} element={<Auth />} />

          <Route path={route.authReferral} element={<Auth />} />

          <Route
            path={route.kyc}
            element={
              <ProtectedRoute>
                <Kyc />
              </ProtectedRoute>
            }
          />

          <Route
            path={route.home}
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          <Route
            path={route.withdraw}
            element={
              <ProtectedRoute>
                <Withdraw />
              </ProtectedRoute>
            }
          />

          <Route
            path={route.gettingStarted}
            element={
              <ProtectedRoute>
                <GettingStarted />
              </ProtectedRoute>
            }
          />

          <Route
            path={route.referral}
            element={
              <ProtectedRoute>
                <Referral />
              </ProtectedRoute>
            }
          />

          <Route
            path={route.statsDecline}
            element={
              <ProtectedRoute>
                <DeclineStats />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<></>} />
        </Routes>
      </>
    </PullToRefresh>
  );
};
