import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';
import { DevTools } from './containers/DevTools';
import { loadStateWithExpiry } from './localstorage';
import { LOCAL_APP_TOKEN } from './constants';

const preloadedState = {
  misc: {
    token: loadStateWithExpiry(LOCAL_APP_TOKEN) || null,
  },
};

export const baseStore = createStore(rootReducer, preloadedState, compose(applyMiddleware(thunk), DevTools.instrument()));
