import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { baseStore } from '../store';
import { LocationData } from '../typings/lists';
import mixpanel from '../mixpanel';

export const handleRequestErrors = (error: AxiosError, defaultMessage?: string) => {
  let useMessage = 'An unexpected error occurred';
  const errorStatus = error.response?.status;

  if (errorStatus?.toString().startsWith('4')) {
    const errors = error.response?.data.errors;
    useMessage = error.response?.data.message || (errors && errors[0].message) || defaultMessage || 'An unexpected error occurred.';
  }

  if (errorStatus === 403 && error.response?.data.message.indexOf('expired') !== -1) {
    mixpanel.reset();
    baseStore.dispatch({ type: 'RESET_STATES' });
  }

  toast.error(useMessage);
};

export const parseLocationData = (str = ''): LocationData | undefined => {
  const [id, name, iso] = (str || '')
    .split('::')
    .filter(Boolean)
    .map((s) => s.trim());

  if (!id || !name) {
    return undefined;
  }

  const data: any = {
    id: Number(id),
    name,
  };

  if (iso) {
    data['iso'] = iso;
  }

  return data;
};

// eslint-disable-next-line valid-jsdoc
/**
 * Get expiry time in milliseconds
 * @param {number} expiresIn In seconds
 * @returns
 */
export const getExpiryFromNow = (expiresIn: number): number => {
  return new Date().getTime() + expiresIn * 1000;
};

export const getQueryParams = (obj: { [x: string]: any }, ignoreEmpty = true): string => {
  const queries = [];

  for (const [k, v] of Object.entries(obj)) {
    if (ignoreEmpty && (v === null || v === undefined)) {
      continue;
    }

    queries.push(`${k}=${v}`);
  }

  return queries.length ? `?${queries.join('&')}` : '';
};

export const formatCurrency = (amount: number, currency: string) => {
  const CurrencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency });
  return CurrencyFormat.format(amount);
};
