import { KycAction, ProfileKycDetailsDto } from '../typings/kyc';

export const setIndividualKycInfo = (details: ProfileKycDetailsDto): KycAction => ({
  type: 'SET_INDIVIDUAL_KYC',
  details,
});

export const updateKycInfo = (details: Partial<ProfileKycDetailsDto>): KycAction => ({
  type: 'UPDATE_KYC_INFO',
  details,
});
