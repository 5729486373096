import React from 'react';
import Gap from '../Gap';

export interface CheckboxProps {
    id?: string;
    name?: string;
    label?: string;
    gapbottom?: number;
  }

export const RadioButton = (props: CheckboxProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const { label, id, gapbottom, name, className, ...restProps } = props;

  return (
    <div className={`custom-radio ${className}`}>
      <input
        type="radio"
        id={id}
        name={name}
        className=''
        {...restProps}
      />
        &nbsp; <label htmlFor={id}>{label}</label>

      {gapbottom ? <Gap v={gapbottom} /> : null}
    </div>

  );
};
