import { UserAction, UserDto } from '../typings/user';

export const setUser = (user: UserDto): UserAction => ({
  type: 'SET_USER',
  user,
});

export const updateUser = (user: Partial<UserDto>): UserAction => ({
  type: 'UPDATE_USER',
  user,
});
