import { ExchangeRate, RatesAction } from '../typings/rates';

export default (state = [], action: RatesAction): ExchangeRate[] => {
  switch (action.type) {
    case 'ADD_RATE':
      return [
        action.rate!,
        ...state.filter(
          ({ fromCurrency, toCurrency }) => fromCurrency !== action.rate?.fromCurrency && toCurrency !== action.rate?.toCurrency,
        ),
      ];
    default:
      return state;
  }
};
