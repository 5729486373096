import React from 'react';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../typings/reducer';
import { formatCurrency } from '../../utils';

export const RatesInfo = () => {
  const { user, rates } = useSelector((state: ReducerStates) => state);

  const localCurrency = user.currency || 'NGN';

  const buyRate = Number(rates.find((rate) => rate.fromCurrency === localCurrency && rate.toCurrency === 'USD')?.rate || 0);
  const sellRate = Number(rates.find((rate) => rate.toCurrency === localCurrency && rate.fromCurrency === 'USD')?.rate || 0);

  return (
    <div className="rate-info-wrap">
      <div className="rate-info">
        <span className="rate">
          Buy: {formatCurrency(buyRate?.toString().startsWith('0.0') ? Number(1 / buyRate) : buyRate, localCurrency)}
        </span>
        <span className="separator"></span>
        <span className="rate">
          Sell: {formatCurrency(sellRate?.toString().startsWith('0.0') ? Number(1 / sellRate) : sellRate, localCurrency)}
        </span>
      </div>
    </div>
  );
};
