export const route = {
  auth: '/auth',
  authReferral: '/r/:referrerCode',
  kyc: '/kyc',
  home: '/',
  referral: '/referral',
  withdraw: '/withdraw/:cardId',
  gettingStarted: '/getting-started',
  statsDecline: '/stats/decline/:cardId',
};
