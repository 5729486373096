import React, { useEffect, useState } from 'react';
import { Header } from '../../components/header';
import { Input } from '../../components/html/Input';
import { Button } from '../../components/html/Button';
import balloonImage from '../../assets/balloon.svg';
import copy from 'clipboard-copy';
import './styles.scss';
import { getReferralData } from '../../api/swiftcards';
import { formatCurrency } from '../../utils';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../typings/reducer';
import Gap from '../../components/Gap';
import { toast } from 'react-toastify';

const REFERRAL_URL = 'https://basic.lasercards.co/r';

const Referral = () => {
  const [enabled] = useState(true);
  const [referralUrl, setReferralUrl] = useState('...');

  const { user, rates, cards } = useSelector((state: ReducerStates) => state);
  const card = cards?.cards?.find((card) => ['ACTIVE', 'FROZEN'].includes(card.status))!;
  const localCurrency = user.currency || 'NGN';
  const buyRate = Number(rates.find((rate) => rate.fromCurrency === localCurrency && rate.toCurrency === 'USD')?.rate || 0);

  useEffect(() => {
    getReferralData().then((res) => {
      setReferralUrl(`${REFERRAL_URL}/${res.referralCode}`);
    });
  }, []);

  return (
    <div className="app-container">
      <div className="app-wrap">
        <div className="home-container">
          <div className="app-inner">
            <Header cardId={card?.id} text={enabled ? 'Refer a friend & earn USD' : ''} backButton={true} />

            <div className="referral-content">
              {enabled && (
                <>
                  <div className="balloon">
                    <img src={balloonImage} alt="referral-balloon" />
                    <p>
                      Earn{' '}
                      <span style={{ color: 'white' }}>
                        <b>$0.5</b>
                        (~ {formatCurrency(0.5 * (1 / buyRate), localCurrency)})
                      </span>{' '}
                      when your friend creates a card and completes their first payment.
                    </p>
                  </div>

                  <div className="col">
                    <Input required disabled id="referralLink" label="Referral Link" name="referralLink" value={referralUrl} />
                  </div>

                  <Gap v={2} />

                  <div className="instructions">
                    <ul>
                      <li>Copy and share your referral code with a friend.</li>
                      <li>Your friend creates a card and uses it for any online payment.</li>
                      <li>You earn rewards instantly.</li>
                    </ul>
                  </div>

                  <div className="button">
                    <Button
                      text="Copy Referral Link"
                      onClick={() => {
                        copy(referralUrl);
                        toast.dark('Copied!', { position: 'bottom-center' });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
