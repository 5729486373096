/* eslint-disable valid-jsdoc */
import Axios, { AxiosRequestConfig } from 'axios';
import { LOCAL_APP_TOKEN } from '../constants';
import { loadStateWithExpiry } from '../localstorage';
import { CustomError } from './CustomError';

const Request = {
  getUserToken(): string {
    const store: any = loadStateWithExpiry(LOCAL_APP_TOKEN);
    return store ? store.token : null;
  },
  /**
   * Prepares request options
   *
   * @param {AxiosRequestConfig} axiosOpts
   * @param {string} authToken
   * @return {Object}
   */
  prepareOptions(axiosOpts: AxiosRequestConfig, authToken: string): Record<string, any> {
    /* eslint-disable-next-line */
    const { url, method, headers, data, ...requestOptions } = axiosOpts || {};

    const options = {
      ...requestOptions,
      headers: {
        ...(headers || {}),
        'x-auth-token': authToken,
      },
    };

    return { data, ...options };
  },
  /**
   * Makes a GET request
   *
   * @param {string}                    endpoint
   * @param {AxiosRequestConfig | null} options
   */
  async get<T>(endpoint: string, options?: AxiosRequestConfig | null): Promise<T> {
    return (await Axios.get(endpoint, options || {})).data;
  },
  /**
   * Makes a POST request
   *
   * @param endpoint
   * @param data
   * @param options
   */
  async post<T>(endpoint: string, options?: AxiosRequestConfig | null): Promise<T> {
    let postData;
    let requestOptions;

    if (options) {
      const { data, ...rest } = options!;

      postData = data;
      requestOptions = rest;
    }

    return (await Axios.post(endpoint, postData, requestOptions)).data;
  },
  /**
   * Makes a POST request
   *
   * @param endpoint
   * @param data
   * @param options
   */
  async put<T>(endpoint: string, options?: AxiosRequestConfig | null): Promise<T> {
    let postData;
    let requestOptions;

    if (options) {
      const { data, ...rest } = options!;

      postData = data;
      requestOptions = rest;
    }

    return (await Axios.put(endpoint, postData, requestOptions)).data;
  },
  /**
   * Makes a POST request
   *
   * @param endpoint
   * @param data
   * @param options
   */
  async patch<T>(endpoint: string, options?: AxiosRequestConfig | null): Promise<T> {
    let postData;
    let requestOptions;

    if (options) {
      const { data, ...rest } = options!;

      postData = data;
      requestOptions = rest;
    }

    return (await Axios.patch(endpoint, postData, requestOptions)).data;
  },
  /**
   * Makes a GET request
   *
   * @param endpoint
   * @param options
   */
  async delete<T>(endpoint: string, options?: AxiosRequestConfig | null): Promise<T> {
    return (await Axios.delete(endpoint, options || {})).data;
  },
  /**
   * Makes a POST request to a service with service token
   *
   * @param endpoint
   * @param options
   */
  async postWithUserToken<T>(endpoint: string, options?: AxiosRequestConfig): Promise<T> {
    const token = this.getUserToken();

    if (!token) {
      throw new CustomError('FORBIDDEN', 'No user token found in state.');
    }

    const opts = this.prepareOptions(options!, token);
    return await this.post<T>(endpoint, opts);
  },
  /**
   * Makes a POST request to a service with service token
   *
   * @param endpoint
   * @param options
   */
  async putWithUserToken<T>(endpoint: string, options?: AxiosRequestConfig): Promise<T> {
    const token = this.getUserToken();

    if (!token) {
      throw new CustomError('FORBIDDEN', 'No user token found in state.');
    }

    const opts = this.prepareOptions(options!, token);
    return await this.put<T>(endpoint, opts);
  },
  /**
   * Makes a POST request to a service with service token
   *
   * @param endpoint
   * @param options
   */
  async patchWithUserToken<T>(endpoint: string, options?: AxiosRequestConfig): Promise<T> {
    const token = this.getUserToken();

    if (!token) {
      throw new CustomError('FORBIDDEN', 'No user token found in state.');
    }

    const opts = this.prepareOptions(options!, token);
    return await this.patch<T>(endpoint, opts);
  },
  /**
   * Makes a GET request to a service with service token
   *
   * @param endpoint
   * @param options
   */
  async getWithUserToken<T>(endpoint: string, options?: AxiosRequestConfig): Promise<T> {
    const token = this.getUserToken();

    if (!token) {
      throw new CustomError('FORBIDDEN', 'No user token found in state.');
    }

    const opts = this.prepareOptions(options!, token);
    return await this.get<T>(endpoint, opts);
  },
  /**
   * Makes a GET request to a service with service token
   *
   * @param endpoint
   * @param options
   */
  async deleteWithUserToken<T>(endpoint: string, options?: AxiosRequestConfig): Promise<T> {
    const token = this.getUserToken();

    if (!token) {
      throw new CustomError('FORBIDDEN', 'No user token found in state.');
    }

    const opts = this.prepareOptions(options!, token);
    return await this.delete<T>(endpoint, opts);
  },
};

export default Request;
