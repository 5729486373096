import React, { useEffect, useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import Cleave from 'cleave.js/react';
import Countdown, { zeroPad } from 'react-countdown';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import OtpInput from 'react18-input-otp';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import serializeForm from 'form-serialize';
import { useSelector } from 'react-redux';
import * as swfcApi from '../../api/swiftcards';
import { ReducerStates } from '../../typings/reducer';
import * as userActions from '../../actions/user';
import * as miscActions from '../../actions/misc';
import * as kycActions from '../../actions/kyc';
import { getExpiryFromNow, handleRequestErrors } from '../../utils';
import { LOCAL_APP_TOKEN, supportedCountryCodes } from '../../constants';
import { saveState } from '../../localstorage';
import { route } from '../../routes';
import { Input } from '../../components/html/Input';
import { Select2 } from '../../components/html/Select';
import { Button } from '../../components/html/Button';
import Gap from '../../components/Gap';
import { ProfileKycDetailsDto } from '../../typings/kyc';
import './styles.scss';
import { toast } from 'react-toastify';
import mixpanel from '../../mixpanel';

const IdOptions: { [x: string]: Array<{ label: string; value: string }> } = {
  NG: [
    {
      label: 'BVN',
      value: 'BVN',
    },
    {
      label: 'NIN',
      value: 'NIN',
    },
  ],
};

export const Kyc = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [chooseOtpTypes, setChooseOtpTypes] = useState(false);
  const [confirmKyc, setConfirmKyc] = useState(false);
  const [selectedOtpTypes, setSelectedOtpTypes] = useState<Array<'SMS' | 'EMAIL'>>([]);
  const [countryCode, setCountryCode] = useState<string>('');
  const [otpCode, setOtpCode] = useState<string>('');
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [kycAuthInfo, setAuthKycInfo] = useState<Partial<ProfileKycDetailsDto>>({});
  const [kycData, setKycData] = useState<Partial<ProfileKycDetailsDto>>({});
  const [attempts, setAttempts] = useState<number>(0);
  const { user, lists, misc } = useSelector((state: ReducerStates) => state);

  const sendKycOtp = async (data: Partial<ProfileKycDetailsDto>) => {
    setLoading(true);

    try {
      if (!confirmKyc || canResendOtp) {
        // update user
        if (data.firstName !== user.firstName || data.lastName !== user.lastName || data.phoneNumber !== user.phoneNumber) {
          await swfcApi
            .updateUser({
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phoneNumber,
            })
            .then((user) => dispatch(userActions.updateUser(user)));
        }

        const requestData: { [x: string]: any } = {
          idType: data.idType,
          idNumber: data.idNumber,
          country: data.country,
        };

        let attemptsCount = 0;
        if (selectedOtpTypes.length > 0) {
          attemptsCount = attempts + 1;
          requestData.attempts = attemptsCount;
          requestData.methods = selectedOtpTypes;
        }

        await swfcApi.verifyKyc(requestData).then((response) => {
          setAuthKycInfo(response.data);

          if (selectedOtpTypes.length === 0) {
            setChooseOtpTypes(true);
          } else {
            dispatch(miscActions.updateMiscSettings({ smsTokenResendTs: attemptsCount >= 2 ? 300000 : 60000 }));
            setAttempts(attemptsCount);
            setCanResendOtp(false);
            setChooseOtpTypes(false);
            setConfirmKyc(true);
          }
        });
      } else {
        await swfcApi.confirmKyc(otpCode).then((response) => {
          saveState(LOCAL_APP_TOKEN, { ...response, expiresIn: getExpiryFromNow(response.expiresIn) });
          navigate(route.home, { replace: true });
          dispatch(miscActions.updateMiscSettings({ token: response }));
          dispatch(kycActions.updateKycInfo({ status: 'VERIFIED' }));
        });
      }
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const updateSelectedOtpType = (type: 'EMAIL' | 'SMS') => {
    let types;

    if (selectedOtpTypes?.includes(type)) {
      types = selectedOtpTypes.filter((typ) => typ !== type);
    } else {
      types = [...selectedOtpTypes, type];
    }

    setSelectedOtpTypes(types);
  };

  useEffect(() => {
    mixpanel.track('View-KYC');
  }, []);

  return (
    <div className="app-container">
      <div className="app-wrap">
        <div className="auth-container">
          <div className="app-inner">
            <div className="app-head app-head-kyc">
              {confirmKyc || chooseOtpTypes ? (
                <a
                  className="back"
                  onClick={() => {
                    if (confirmKyc) {
                      setConfirmKyc(false);
                      setChooseOtpTypes(true);
                    }

                    if (chooseOtpTypes) {
                      setAttempts(0);
                      setSelectedOtpTypes([]);
                      setChooseOtpTypes(false);
                    }

                    setConfirmKyc(false);
                  }}
                ></a>
              ) : null}

              <img src={require('../../assets/logo.png')} alt="" height={40} />

              {chooseOtpTypes ? (
                <div className="max-w-350x margin-auto_center">
                  <h4>Receive OTP</h4>
                  <p>Choose how you will like to receive your OTP</p>
                </div>
              ) : confirmKyc ? (
                <div className="max-w-350x margin-auto_center">
                  <h4>Verify Account</h4>
                  <p>
                    Enter the 6-digit code sent to{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: [
                          selectedOtpTypes.includes('SMS') ? `your phone number <a>${kycAuthInfo.phoneNumber}</a>` : null,
                          selectedOtpTypes.includes('EMAIL') ? `your email <a>${kycAuthInfo.email}</a>` : null,
                        ]
                          .filter(Boolean)
                          .join(' and '),
                      }}
                    />
                  </p>
                </div>
              ) : (
                <p>Complete your registration</p>
              )}

              {!confirmKyc ? <a className="info" onClick={() => navigate(route.gettingStarted)}></a> : null}
            </div>

            <Gap v={1} />

            <form
              style={{ padding: '25px' }}
              onSubmit={async (ev) => {
                ev.preventDefault();

                if (chooseOtpTypes && selectedOtpTypes.length === 0) {
                  return toast.error('Please choose how you will like to receive your OTP');
                }

                const form = ev.target as any;
                const { firstName, lastName, idType, idNumber, phoneCode, phone, country } = serializeForm(form, { hash: true });
                const phoneStr = parsePhoneNumber(phone, 'NG').nationalNumber as string;
                const phoneNumber = '+' + phoneCode + phoneStr.replace(/^0+/, '').replace(/\s/g, '');

                const data = {
                  firstName,
                  lastName,
                  idType,
                  idNumber,
                  phoneNumber,
                  country,
                };

                setKycData(data);
                sendKycOtp(data);
              }}
            >
              <div style={{ display: confirmKyc || chooseOtpTypes ? 'none' : 'block' }}>
                <div className="row">
                  <div className="col">
                    <Input
                      required
                      id="input-fn"
                      label="First Name"
                      name="firstName"
                      defaultValue={user.firstName}
                      placeholder="Ex. John"
                      gapbottom={1}
                    />
                  </div>

                  <div className="col">
                    <Input
                      required
                      id="input-ln"
                      label="Last Name"
                      name="lastName"
                      defaultValue={user.lastName}
                      placeholder="Ex. Doe"
                      gapbottom={1}
                    />
                  </div>
                </div>

                <div className="form-group select_input">
                  <label htmlFor="input-phone" className="mb-2">
                    Phone Number
                  </label>
                  <div className="wrap">
                    <Select2
                      required
                      name="phoneCode"
                      options={lists.countries
                        ?.filter((country) => supportedCountryCodes.includes(country.isoCode))
                        .map(({ phoneCode }) => ({
                          label: `+${phoneCode.toString()}`,
                          value: phoneCode.toString(),
                        }))}
                    />
                    <div className="separator"></div>
                    <Cleave
                      required
                      name="phone"
                      autoComplete="off"
                      id="input-phone"
                      className="form-control"
                      placeholder="Ex. 8051235678"
                      value={
                        // prettier-ignore
                        user?.phoneNumber ? (parsePhoneNumber(user.phoneNumber as string, 'NG').nationalNumber as string) : ''
                      }
                      options={{
                        phone: true,
                        phoneRegionCode: 'any',
                      }}
                    />
                  </div>
                </div>

                <Gap v={1} />

                <Select2
                  required
                  id="sel-natn"
                  name="country"
                  label="Nationality"
                  options={[
                    {
                      label: 'Select one',
                      value: '',
                    },
                    ...lists.countries
                      ?.filter((country) => supportedCountryCodes.includes(country.isoCode))
                      .map(({ id, name, isoCode }) => ({
                        label: name,
                        value: `${id}::${name}::${isoCode}`,
                      }))!,
                  ]}
                  onChange={(ev) => {
                    const [, , countCode] = ev.target.value.split(/\s*::\s*/);
                    setCountryCode(countCode);
                  }}
                  gapbottom={1}
                />

                <Select2
                  required
                  id="sel-midn"
                  name="idType"
                  label="Means of Identification"
                  options={IdOptions[countryCode]}
                  gapbottom={1}
                />

                <Input required id="input-idn" label="Identification Number" name="idNumber" placeholder="Ex. 12345678912" gapbottom={1} />
              </div>

              <div style={{ display: confirmKyc && !chooseOtpTypes ? 'block' : 'none' }}>
                <OtpInput
                  value={otpCode}
                  numInputs={6}
                  shouldAutoFocus={true}
                  placeholder={'•'.repeat(6)}
                  onChange={(otp: string) => setOtpCode(otp)}
                  containerStyle="otp-input-container"
                  inputStyle="form-control"
                  isInputNum={true}
                />
                <Gap v={4} />
              </div>

              <div style={{ display: chooseOtpTypes ? 'block' : 'none' }}>
                <ul className="otp-type-select">
                  <li
                    style={{ display: kycAuthInfo.email ? 'block' : 'none' }}
                    className={`${selectedOtpTypes.includes('EMAIL') ? 'selected' : ''}`}
                    onClick={() => updateSelectedOtpType('EMAIL')}
                  >
                    <p className="title">Email verification</p>
                    <p className="info">
                      Your OTP would be sent to <a>{kycAuthInfo.email}</a>
                    </p>
                  </li>

                  <li
                    style={{ display: kycAuthInfo.phoneNumber ? 'block' : 'none' }}
                    className={`${selectedOtpTypes.includes('SMS') ? 'selected' : ''}`}
                    onClick={() => updateSelectedOtpType('SMS')}
                  >
                    <p className="title">Phone verification</p>
                    <p className="info">
                      Your OTP would be sent to <a>{kycAuthInfo.phoneNumber}</a>
                    </p>
                  </li>
                </ul>

                <Gap v={3} />

                <div className="note note-style-2 warning">
                  <div className="title">Assisted Verification </div>
                  <p>
                    If you no longer have access, reach out to support via <a href="mailto:help@lasercards.co">help@lasercards.co</a>
                  </p>
                </div>

                <Gap v={2} />
              </div>

              <Gap v={1} />
              <Button
                loading={loading}
                text={loading ? 'Please wait...' : chooseOtpTypes ? 'Send OTP' : confirmKyc ? 'Verify' : 'Continue'}
              />

              {confirmKyc ? (
                <div className="text-center">
                  <Gap v={2} />

                  <p>
                    Didn’t receive an OTP? {!canResendOtp ? 'Resend in' : ''} <br />
                    {canResendOtp ? (
                      <a className="cursor-pointer" onClick={() => sendKycOtp(kycData)}>
                        Resend OTP
                      </a>
                    ) : (
                      <a>
                        <Countdown
                          date={Date.now() + misc.smsTokenResendTs!}
                          renderer={({ minutes, seconds }) => `${zeroPad(minutes)}:${zeroPad(seconds)}`}
                          onTick={({ total }) => {
                            dispatch(miscActions.updateMiscSettings({ smsTokenResendTs: total }));
                            if (total <= 1000) {
                              setCanResendOtp(true);
                            }
                          }}
                        />
                      </a>
                    )}
                  </p>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
